import { createContext, FC, useContext, useEffect, useState } from "react";
import React from "react";
import styles from "../styles/header.module.css";
import errorStyles from '../styles/StripeContext.module.css';
import Plausible from 'plausible-tracker'


import {
  ErrorResponse,
  ExposedError,
  ISdkManagedPaymentIntent,
  loadStripeTerminal,
  Reader,
  Terminal,
} from "@stripe/terminal-js";
import axios from "axios";
import testCard from "../test-card";
import StripeError from "../errors/StripeError";
import { collapseToast, toast, Zoom } from "react-toastify";
import { Link } from "react-router-dom";
const MC_Sonic = require("../assets/audio/MC_SONIC_SOUND.mp3");

export type StripeContextType = {
  terminal: Terminal | null;
  connectToReader: (reader: Reader) => Promise<void>;
  connectionStatus: string;
  pay: (amount: number, currency: string) => Promise<void>;
  isPaymentInProgress: boolean;
};

const StripeContextDefaultValues: StripeContextType = {
  terminal: null,
  connectToReader: async (reader: Reader) => {},
  connectionStatus: "not_connected",
  pay: async (amount: number, currency: string) => {},
  isPaymentInProgress: false,
};

const StripeContext = createContext<StripeContextType>(
  StripeContextDefaultValues
);

const useStripe = (): StripeContextType => {
  return useContext(StripeContext);
};

const fetchConnectionToken = async () => {
  try {
    const response = await axios.post("/api/connection_token");
    return response.data.secret;
  } catch (error) {
    console.error("fetchConnectionToken Error: " + error);
  }
};

const unexpectedDisconnect = () => {
  // In this function, your app should notify the user that the reader disconnected.
  // You can also include a way to attempt to reconnect to a reader.
  console.log("Disconnected from reader");
};

const StripeProvider: FC = ({ children }) => {
  const [terminal, setTerminal] = useState<Terminal | null>(null);
  const [open, setOpen] = React.useState(false);
  const [paymentStatus, setPaymentStatus] = useState<boolean>(false);

  const [isPaymentInProgress, setIsPaymentInProgress] =
    useState<boolean>(false);

  const [isError, setError] = useState<boolean>(false);

  const [connectionStatus, setConnectionStatus] =
    useState<string>("not_connected");
  useEffect(() => {
    initTerminal();
  }, []);

  const initTerminal = async () => {
    const stripeTerminal = await loadStripeTerminal();
    if (stripeTerminal) {
      const terminal = stripeTerminal.create({
        onFetchConnectionToken: fetchConnectionToken,
        onUnexpectedReaderDisconnect: unexpectedDisconnect,
      });

      setTerminal(terminal);
    } else {
      console.log("ERROR TERMINAL INIT");
    }
  };

  const collectPaymentMethod = async (
    secret: string
  ): Promise<ISdkManagedPaymentIntent> => {
    // uncomment for testing
    // terminal!.setSimulatorConfiguration({
    //   testCardNumber: testCard.currentCard,
    // });
    const clientSecretResult = await terminal!.collectPaymentMethod(secret);
    const intent: ISdkManagedPaymentIntent = (clientSecretResult as any)
      .paymentIntent;

    return intent;
  };

  const processPayment = async (
    intent: ISdkManagedPaymentIntent
  ): Promise<void> => {
    const result = await terminal!.processPayment(intent);

    if ((result as ErrorResponse).error) {
      throw new StripeError((result as ErrorResponse).error);
    } else {
      const paymentIntentId = (result as any).paymentIntent.id;

      await captureIntent(paymentIntentId);
    }
  };
  


  const captureIntent = async (paymentIntentId: string): Promise<void> => {
    const response = await axios.post("/api/capture_payment_intent", {
      id: paymentIntentId,
    });
    const cardType = response.data.charges.data[0].payment_method_details.card_present.brand;
    const cardCompletion = response.data.charges.data[0].status;
    if (cardCompletion === "succeeded") {
      setPaymentStatus(true);
    } else {
      setPaymentStatus(false);
    }
    console.log('Card Completion Success?: ' + cardCompletion);
    console.log('Card Type?: ' + cardType);
    console.log(response.data);

    const { trackEvent } = Plausible({
      domain: process.env.REACT_APP_PLAUSIBLE_DOMAIN,
      trackLocalhost: true,
    })

    trackEvent('Card-Type', { props: { cardType } })
    trackEvent('Card-Completion', { props: { cardCompletion } })
    
     if (cardType === "mastercard") {
      // window.location.replace('thank-you');
      console.log('Playing Mastercard Audio')
      var audio = new Audio(MC_Sonic);
      audio.play();

      setTimeout(function(){
        window.location.href = '/thank-you';
     }, 5000);

    } else {
      console.log('Not playing Mastercard Audio')
      setTimeout(function(){
        window.location.href = '/thank-you';
     }, 5000);    }

  };



  const pay = async (amount: number, currency: string): Promise<void> => {
    setIsPaymentInProgress(true);
    
    try {
      const clientSecret = await createPaymentIntent(amount, currency);
      const paymentIntent = await collectPaymentMethod(clientSecret);

      const toastCompletionText = () => {
        return(
          <>
          <svg className={styles.checkmark} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle className={styles.checkmark__circle} cx="26" cy="26" r="25" fill="none"/>
            <path className={styles.checkmark__check} fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
          </svg>
          <p className={styles.toastCompletion}>Thank you! Payment complete.</p>
          </>
        )
      }

      await processPayment(paymentIntent);
      // toast("Payment completed successfully!");
      toast(toastCompletionText, {
        position: "top-center",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        toastId: "payment-success",
        delay: 1000,
        transition: Zoom,
        closeButton: false,
        });

      

    } catch (ex) {
      const exception = ex as StripeError;
      setIsPaymentInProgress(false);
      handleError(exception.error);
      // handleErrorMessage(exception.error);
      // handleOpen();

    }
  };

  const createPaymentIntent = async (
    amount: number,
    currency: string
  ): Promise<string> => {
    const content = { amount: amount, currency: currency };
    const response = await axios.post("/api/create_payment_intent", content);

    return response.data.client_secret;
  };

  const connectToReader = async (reader: Reader): Promise<void> => {
    const result = await terminal!.connectReader(reader);

    if ((result as ErrorResponse).error) {
      console.log(( result as ErrorResponse).error);
      toast(( result as ErrorResponse).error , {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        toastId: "payment-in-error",
        transition: Zoom,
        delay: 1000,
        closeButton: false,

        });
    } else {
      setConnectionStatus("connected");
    }
  };



  
  const handleError = (error: ExposedError) => {
    const toastErrorText = () => {
      return(
        <>
        <svg className={errorStyles.xmark} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
          <circle className={errorStyles.xmark__circle} cx="26" cy="26" r="25" fill="none" />
          <path className={errorStyles.xmark__check} fill="none" d="M16 16 36 36 M36 16 16 36" />
        </svg>
        <div>
          <p className={errorStyles.toastError}>{error.message}</p>
        </div>
        </>
      )
    }
    console.log(error);
    // toast(error.message)
    setError(true);
    toast(toastErrorText, {
      position: "top-center",
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      toastId: 'payment-in-error',
      transition: Zoom,
      delay: 1000,
      closeButton: false,
      
      });
    };



    return (
      <StripeContext.Provider
      value={{
        terminal,
        isPaymentInProgress,
        connectToReader,
        connectionStatus,
        pay,

      }}
      >
      {children}
    </StripeContext.Provider>
  );
};

export default useStripe;
export { useStripe, StripeProvider };