import { FC, useState } from "react";
import { toast } from "react-toastify";
import useStripe from "../context/StripeContext";
import testCard from "../test-card";
import styles from "../styles/header.module.css";
import onetrees from '../assets/1-tree.png';
import twotrees from '../assets/2-trees.png';
import fivetrees from '../assets/5-trees.png';
import tentrees from '../assets/10-trees.png';
import Loader from "./Loader";

interface ITestPayButtonProps {
  amount?: number;
  currency?: string;
  cardNumber: string;
  label: string;
  className?: string;
}

const TestPayButton: FC<ITestPayButtonProps> = ({
  cardNumber,
  label,
  amount = 5,
  currency = "cad",
  className = "",
}) => {
  const { pay, isPaymentInProgress } = useStripe();

  const [
    isCurrentButtonPaymentInProgress,
    setIsCurrentButtonPaymentInProgress,
  ] = useState<boolean>(false);

  const payAmount = async (amount: number) => {
    if (!isPaymentInProgress) {
      testCard.currentCard = cardNumber;
      setIsCurrentButtonPaymentInProgress(true);
      await pay(amount * 100, currency);
      testCard.currentCard = testCard.defaultCard;
      setIsCurrentButtonPaymentInProgress(false);
    } else {
      toast("Payment in process!");
    }
  };

  const trees = () => {
    if (amount === 2 ) {
      return (
        <span className={styles.treeHolder}>
          <img src={onetrees} alt="tree" className={styles.tree}/>
        </span>
      )
    } if (amount === 4 ){
      return (
        <span className={styles.treeHolder}>
          <img src={twotrees} alt="tree" className={styles.tree}/>
        </span>
      )
    } if (amount === 10 ){
      return (
        <span className={styles.treeHolder}>
          <img src={fivetrees} alt="tree" className={styles.tree}/>
        </span>
      )
    } if (amount === 20 ){
      return (
        <div className={styles.treeHolder}>
          <img src={tentrees} alt="tree" className={styles.tree}/>
        </div>
      )

    }
  }

  return (
    // <button
    //   onClick={() => payAmount(amount)}
    //   type="button"
    //   className={`w-60 text-gray-900 bg-white border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-5 text-center dark:bg-gray-600 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-800 ${className}`}
    // >
    //   {isCurrentButtonPaymentInProgress && (
    //     <span>
    //       <Loader message="Processing payment..." />
    //     </span>
    //   )}
    //   {!isCurrentButtonPaymentInProgress && <span>{label}</span>}
    // </button>
  
    <button
    onClick={() => payAmount(amount)}
    type="button"
    // className={`flex align-middle  w-9/12 h-36 text-center text-gray-900 bg-white border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-blue-300 font-medium rounded-full	 text-5xl  dark:white dark:text-black dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-800 ${className}`}
    className={` m-5 inline-flex items-center align-middle  h-36 text-center text-gray-900 bg-white border font-medium rounded-full	 text-5xl ${styles.donateButton} `}
  >
    {isCurrentButtonPaymentInProgress && (
       <span className={`w-full ml-12 inline-flex items-center	align-middle`}>
       <span className={styles.donateButtonText}>DONATE </span> ${amount} {currency.toUpperCase()}
     {trees()}
   </span>
    )}
    {!isCurrentButtonPaymentInProgress && (
      <span className={`w-full ml-12 inline-flex items-center	align-middle`}>
          <span className={styles.donateButtonText}>DONATE </span> ${amount} {currency.toUpperCase()}
        {trees()}
      </span>
    )}
  </button>
    );
};

export default TestPayButton;
