import { FC, useEffect, useState } from "react";
import { Link, } from "react-router-dom";
import useStripe from "../context/StripeContext";
import Dashboard from "./Dashboard";
import ReaderConnect from "./ReaderConnect";
import Header from "./Header";
import Thankyou from "./Thankyou";
import axios from "axios";
import {
  BrowserRouter as Router,
  // Switch,
  Route,
  // Redirect,
} from "react-router-dom";

interface IHomeProps {}

const Home: FC<IHomeProps> = ({}) => {
  const { connectionStatus } = useStripe();




  const [isReaderConnected, setIsReaderConnected] = useState<boolean>(false);
  // console.log(isReaderConnected);

  useEffect(() => {
    setIsReaderConnected(connectionStatus == "connected");
  }, [connectionStatus]);

  return (
    <div>
      <Header />

      {isReaderConnected && <Link to={"/test"}></Link>}
      
      {isReaderConnected && <Dashboard />}
      {!isReaderConnected && <ReaderConnect />}
    </div>
  );
};

export default Home;
