import styles from "../styles/thankyou.module.css";
import Header from "./Header";
import ThankYouMessage from "../utils/thankyou";
import { useState, useEffect } from "react";
// import API from "../utils/service";
const ThankYouBG = require("../assets/Thankyou_BG.mp4");


if (window.location.pathname === '/thank-you') {
    setTimeout(function(){
        window.location.href = '/';
     }, 6000);
    console.log('This is the thank you page');
} else {
    // console.log('This is not the thank you page');
}

const Thankyou = () => {

  // const [thankyou, setThankyou] = useState('');
  // useEffect(() => {
  //   async function run() {
  //     try {
  //       const items = await API.retrieveThankYou();
  //       setThankyou(items.data.Thank_You_Messaging);
  //     } catch (e) {
  //       console.error(e);
  //     }
  //   }
    
  //   run();
    
  //   const interval = setInterval(run, 5000);
    
  //   return () => clearInterval(interval);
  // }, []);
  
  // console.log(thankyou);

  return (
    <div className={styles.thankyou}>
      <Header />
      <div className="flex flex-col items-center">
        <div className={styles.myvideo}>
          <video className={styles.video} src={ThankYouBG} autoPlay loop muted />
        </div>
        <div className={styles.bodyHeader}>
        Thank you for your donation.
        </div>

        {/* <ThankYouMessage /> */}
      </div>
    </div>
  );
};
export default Thankyou;
