import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Test from "./components/Test";
import Thankyou from "./components/Thankyou";


interface IAppProps {}

const App: FC<IAppProps> = ({}) => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="test" element={<Test />} />
      <Route path="/thank-you" element={<Thankyou />} />
    </Routes>
  );
};

export default App;
