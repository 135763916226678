import { ExposedError } from "@stripe/terminal-js/types/terminal";

class StripeError extends Error {
  error: ExposedError;

  constructor(error: ExposedError) {
    super();
    this.error = error;
  }
}
export default StripeError;
