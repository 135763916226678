import { FC } from "react";
import PayButton from "./PayButton";
import plant from "../assets/plant.jpg";
import styles from "../styles/header.module.css";
// import Donation from '../utils/donation';
import {useEffect, useState} from 'react';
// import API from '../utils/service';
import Screensaver from "./Screensaver";
import Header from "./Header";


interface IDashboardProps {}

const Dashboard: FC<IDashboardProps> = (props) => {
  
  const payAmounts = [2, 4, 10, 20];
  
  // const [header, setHeader] = useState('');
  // const [body, setBody] = useState('');
  // const [legalHeader, setLegalHeader] = useState('');
  // const [legalSubHeader, setLegalSubHeader] = useState('');
  // const [legalBody, setLegalBody] = useState('');
  const [showScreensaver, setShowScreensaver] = useState(true);
  
  // useEffect(() => {
  //   async function run() {
  //     try {
  //       const items = await API.retrieveDonation();
  //       setHeader(items.data.Header);
  //       setBody(items.data.Body);
  //       setLegalHeader(items.data.Legal_Disclosure_Header);
  //       setLegalSubHeader(items.data.Legal_Disclosure_Subheader);
  //       setLegalBody(items.data.Legal_Disclosure_Body);
  //       // console.log(items);
  //     } catch (e) {
  //       console.error(e);
  //     }
  //   }
  
  //   run();
  
  //   const interval = setInterval(run, 5000);
  
  //   return () => clearInterval(interval);
  // }, [props]);

  const handleOnClick = () => {
    setShowScreensaver(false);
  }

  return (
    
    <div className=" flex items-center flex-col ">
      {showScreensaver === true 
        ? <div className={''} onClick={() => handleOnClick()}>
              <Screensaver />
          </div> : 
        <div className=" flex items-center flex-col ">
        <div className={styles.payWall}>
          <div className={styles.payWallContent}>
            {/* <Donation /> */}
            <div className={styles.bodyHeader}>
            Plant a tree
            </div>
          <div className={styles.bodyText}>
          Start Something Priceless™ and contribute to the future of our planet by planting a tree today as we seek to restore 100 million trees by 2025.
          </div>
            <div className={styles.donateHeader}>
              Donate now:
            </div>
          </div>
        </div>
        {payAmounts.map((amount: number, i: number) => (
          <PayButton key={i} amount={amount} className="mb-10"></PayButton>
        ))}
          <div className={styles.legalDisclosure}>
              <p>
              Cost per tree is approximate.
              </p>
              <br />
              <p>
              Your donation will be made to Conservation International, with proceeds granted to forest restoration projects fulfilled by both Conservation International and World Resource Institute. 
              </p>
                <br />
              <p>
              Conservation International has been recognized by the IRS as a 501(c)(3) charitable organization. Your
donation (less any applicable standard card processing fees) will be paid directly, and typically without
delay, to the Charity. Mastercard is not serving as a professional fundraiser on behalf of the Charity and
Mastercard does not charge charities to be listed on this platform. Mastercard is serving as the donation
platform provider solely to help facilitate the donation. We won’t share any personally identifiable
information you provide in connection with this donation with the Charity. Your donation is subject to
the Terms of Use for the Mastercard Donation Service, which can be found at: https://mstr.cd/TOU.
Please consult you own tax advisor as to the deductibility of your donation as Mastercard makes no
representations or warranties as to any such deductibility.
              </p>
          </div>
      </div>
      }
      
    </div>
  );
};

export default Dashboard;
