import { useEffect, useState } from "react";

import Loader from "./Loader";
import styles from "../styles/header.module.css";
const CTA = require("../assets/cta/CTA_Thin_Donate.mp4");

const Screensaver = () => {
    const [onClick, setClicked] = useState(false);
  
// const handleClicked = () => {
//     setClicked(true);
// };





  return (
    <>

        <div className="flex flex-col items-center">
          <div className={styles.myvideo}>
            <video className={styles.video} src={CTA} autoPlay loop muted />
            {/* <div className={styles.content} 
            onClick={() => handleClicked()}>
            </div> */}
        </div>
        </div>

    </>
  );
};

export default Screensaver;
