import { FC, useEffect, useState } from "react";
import useStripe from "../context/StripeContext";
import { DiscoverResult, ErrorResponse, Reader } from "@stripe/terminal-js";
import Loader from "./Loader";
import styles from "../styles/header.module.css";
const CTA = require("../assets/cta/CTA_Thin_Donate.mp4");
interface IReaderConnectProps {}

const ReaderConnect: FC<IReaderConnectProps> = ({}) => {
  const { terminal, connectToReader } = useStripe();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [readers, setReaders] = useState<Reader[]>([]);

  useEffect(() => {
    if (terminal !== null) {
      discoverReaders();
      
    }
  }, [terminal]);

  const discoverReaders = async () => {
    var config = { simulated: false }; //set to true for testing

    const result = await terminal!.discoverReaders(config);
    if ((result as ErrorResponse).error) {
      console.error((result as ErrorResponse).error);
      setIsLoading(false);
    } else {
      setReaders((result as DiscoverResult).discoveredReaders);
      setIsLoading(false);
    }
  };

  const connect = async (reader: Reader) => {
    console.log(reader)
    setIsLoading(true);
    await connectToReader(reader);
    setIsLoading(false);
  };
  // console.log('READER: ' + readers[0] + 'IP_ADDRESS: ' + readers[0]);
  
  // on page load, connect to the reader
  useEffect(() => {
    if (readers.length > 0) {
      connect(readers[0]);
    }  
  }, [readers]);






  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <div className="flex flex-col items-center">
          <div className={styles.myvideo}>
            <video className={styles.video} src={CTA} autoPlay loop muted />
          {/* {readers.map((reader: Reader, i: number) => (
              <div className={styles.content} key={i}
              onClick={() => connect(reader)}>
           
          </div>
          ))} */}
        </div>
        </div>
      )}
      <div></div>
    </>
  );
};

export default ReaderConnect;
