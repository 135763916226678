import { FC, useState } from "react";
import React from "react";
import { toast, Zoom } from "react-toastify";
import useStripe from "../context/StripeContext";
import styles from "../styles/header.module.css";
import onetrees from '../assets/trees/1-tree.png';
import twotrees from '../assets/trees/2-trees.png';
import fivetrees from '../assets/trees/5-trees.png';
import tentrees from '../assets/trees/10-trees.png';
import Loader from "./Loader";
import Plausible from 'plausible-tracker'
import axios from "axios";


interface IPayButtonProps {
  amount: number;
  currency?: string;
  className?: string;
}
const PayButton: FC<IPayButtonProps> = ({
  amount,
  currency = "USD",
  className = "",
}) => {
 
  const { pay, isPaymentInProgress } = useStripe();
  console.log('Is Payment in Progress?: ' + isPaymentInProgress);
  
  const [
    isCurrentButtonPaymentInProgress,
    setIsCurrentButtonPaymentInProgress,
  ] = useState<boolean>(false);

  const toastContent = () => {
    return (
      <>
        <Loader message=""/>
        <span className={styles.modalPaymentHeader}>
          Please tap your card on the terminal  
        </span>
      </>
      )
  
  }




  const payAmount = async () => {

    const { trackEvent } = Plausible({
      domain: process.env.REACT_APP_PLAUSIBLE_DOMAIN,
      trackLocalhost: true,
    })
    
    if (!isPaymentInProgress) {
      setIsCurrentButtonPaymentInProgress(true);
      const toastId = toast(toastContent, {
        position: "top-center",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        toastId: "payment-in-progress",
        transition: Zoom,
        closeButton: false,
      });
      // handleOpen();
      
      console.info('Starting Inactive Timer For Payment');
      setTimeout(function(){
        setIsCurrentButtonPaymentInProgress(false);
        // disconnectFromReader();
        window.location.href = '/';
        trackEvent('Payment-Timeout', { props: { isPaymentInProgress } })
      }, 30000);
      
      await pay(amount * 100, currency);
      trackEvent('Payment', { props: {amount} })
      toast.dismiss(toastId);
      setIsCurrentButtonPaymentInProgress(false);
    } else {
      toast("Payment in process!");
      console.warn('Payment in process!');
    }
  };

  const trees = () => {
    if (amount === 2 ) {
      return (
        <span className={styles.treeHolder}>
          <img src={onetrees} alt="tree" className={styles.tree}/>
        </span>
      )
    } if (amount === 4 ){
      return (
        <span className={styles.treeHolder}>
          <img src={twotrees} alt="tree" className={styles.tree}/>
        </span>
      )
    } if (amount === 10 ){
      return (
        <span className={styles.treeHolder}>
          <img src={fivetrees} alt="tree" className={styles.tree}/>
        </span>
      )
    } if (amount === 20 ){
      return (
        <div className={styles.treeHolder}>
          <img src={tentrees} alt="tree" className={styles.tree}/>
        </div>
      )

    }
  }
 
  return (
    <button
    onClick={() => payAmount()}
    type="button"
    // className={`flex align-middle  w-9/12 h-36 text-center text-gray-900 bg-white border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-blue-300 font-medium rounded-full	 text-5xl  dark:white dark:text-black dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-800 ${className}`}
    className={` m-5 inline-flex items-center align-middle  h-36 text-center text-gray-900 bg-white border font-medium rounded-xl	 text-5xl ${styles.donateButton} `}
    >
        {isCurrentButtonPaymentInProgress && (
        <span className={`w-full inline-flex justify-center	items-center align-middle p-6`}>
              <span className='p-5'>${amount}</span>=
            {/* <span className={styles.donateButtonText}>DONATE </span> ${amount} {currency.toUpperCase()} */}
          {trees()}
        </span>
      )}
      {!isCurrentButtonPaymentInProgress && (
        <span className={`w-full inline-flex justify-center	items-center align-middle p-6`}>
             <span className='p-5'>${amount}</span>=
            {/* <span className={styles.donateButtonText}>DONATE </span> ${amount} {currency.toUpperCase()} */}
          {trees()}
        </span>
      )}
    </button>
  );
};

export default PayButton;
