import { FC, useEffect } from "react";
import useStripe from "../context/StripeContext";
import TestPayButton from "./TestPayButton";
import { Link, useNavigate } from "react-router-dom";

//https://stripe.com/docs/terminal/references/testing
interface ITestProps {}

interface ICardTestCase {
  cardNumber: string;
  label: string;
}

const testCases: ICardTestCase[] = [
  { cardNumber: "4000000000000002", label: "Charge Declined" },
  { cardNumber: "4000000000009995", label: "Insufficient Funds" },
  { cardNumber: "4000000000009987", label: "Lost Card" },
  { cardNumber: "4000000000009979", label: "Stolen Card" },
  { cardNumber: "4000000000000069", label: "Expired Card" },
  { cardNumber: "4000000000000119", label: "Processing Error" },
];

const Test: FC<ITestProps> = ({}) => {
  const { connectionStatus } = useStripe();
  const navigate = useNavigate();
  useEffect(() => {
    if (connectionStatus !== "connected") {
      navigate("/");
    }
  }, []);

  return (
    <>
      <Link to={"/"}>Home</Link>
      <div className="flex mt-10 items-center flex-col">
        {testCases.map((testCase: ICardTestCase, i: number) => (
          <TestPayButton
            key={i}
            className="mb-10"
            cardNumber={testCase.cardNumber}
            label={testCase.label}
          ></TestPayButton>
        ))}
      </div>
    </>
  );
};

export default Test;
