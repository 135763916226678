import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import { StripeProvider } from "./context/StripeContext";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import * as Sentry from "@sentry/react";
import Plausible from 'plausible-tracker'
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import { BrowserTracing } from "@sentry/tracing";

// if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://80e6f860082749c99143d6cf770cdee3@o1413651.ingest.sentry.io/4503932364521472",  
    autoSessionTracking: true, // default: true

    integrations: [
      new BrowserTracing(),
      new CaptureConsoleIntegration({
        // array of methods that should be captured
        // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
        levels: ['log', 'info', 'warn', 'error', 'debug', 'assert']
      })],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
// }

const { enableAutoPageviews, trackEvent } = Plausible({
  domain: process.env.REACT_APP_PLAUSIBLE_DOMAIN,
  trackLocalhost: true,
})

enableAutoPageviews()




// Can also use with React Concurrent Mode
// ReactDOM.createRoot(document.getElementById('root')).render(<App />);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <StripeProvider>
        <App />
        <ToastContainer />
      </StripeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
